import { Button } from '@pushpay/button';
import { ServiceUnavailableIllustration } from '@pushpay/illustration';
import { Text } from '@pushpay/layout';
import { ComponentProps } from '@pushpay/types';

import useTranslation from '@src/translations';

import { useStyles } from './contentNotLoadedStyles';

type TargetLevel = 'page' | 'widget' | 'date';

export interface ContentNotLoadedProps {
  target?: TargetLevel;
}

export type ContentNotLoadedComponentProps = ComponentProps<ContentNotLoadedProps, typeof useStyles>;

export const ContentNotLoaded = ({ classes: classesProps, target = 'widget' }: ContentNotLoadedComponentProps) => {
  const classes = useStyles(classesProps);
  const { translate } = useTranslation();
  let text;
  switch (target) {
    case 'page':
      text = translate('errors.contentNotLoadedPage');
      break;
    case 'date':
      text = translate('errors.dateRangeTooLarge');
      break;
    default:
      text = translate('errors.contentNotLoaded');
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.pageContent}>
        <ServiceUnavailableIllustration classes={{ root: classes.illustration }} />
        <Text className={classes.errorText} type="text-4">
          {text}
        </Text>
        <Button
          type="button"
          displayStyle="secondary"
          onClick={() => window.location.reload()}
          data-pp-at-target="reload-btn"
          style={{ margin: '8px 0' }}
        >
          {translate('refreshPage')}
        </Button>
      </div>
    </div>
  );
};
