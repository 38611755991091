/*
 * This file is automatically generated.
 * Do not add changes in here.
 */

import * as React from 'react';

import { Moment } from 'moment';

import { useTranslationI18next, TransI18next, TransPropsI18next, UseTranslationOptions } from '@pushpay/i18n';

export type TranslationLanguage = {
	common: {
		'aboutText.dashboard': never;

		'aboutText.home': never;

		'aboutText.overview': never;

		aboutThisPage: never;

		attendance: never;

		'attendanceOverview.latest.description': never;

		'attendanceOverview.latest.heading': never;

		attenders: never;

		beta: never;

		cancel: never;

		'charts.axisBottomLegend': never;

		'charts.axisLeftLegend.AttendanceOverview': never;

		'charts.axisLeftLegend.GivingOverview': never;

		'charts.axisLeftLegend.GroupParticipationOverview': never;

		'charts.axisLeftLegend.ServingOverview': never;

		'charts.customDateRangeLegend': {
			number: string | number;
		};

		close: never;

		collapseMenu: never;

		'contactSupport.linkText': never;

		'contactSupport.linkTextPrefix': never;

		'contactSupport.linkTextSuffix': never;

		'contactSupport.noPermissions.insights': never;

		'contactSupport.noPermissions.page': never;

		csv: never;

		dashboard: never;

		'dashboardWidget.groupParticipation.subTitle': never;

		'dashboardWidget.groupParticipation.title': never;

		'dashboardWidget.whatsNew': never;

		'dataConfidence.attendanceOverview': never;

		'dataConfidence.dashboard.Attenders': never;

		'dataCustomization.membershipType.description': never;

		'dataCustomization.membershipType.dropdown.allOptionsSelected': never;

		'dataCustomization.membershipType.dropdown.applyButtonLabelText': never;

		'dataCustomization.membershipType.dropdown.clear': never;

		'dataCustomization.membershipType.dropdown.noMembershipTypeOption': never;

		'dataCustomization.membershipType.dropdown.noOptionsSelected': never;

		'dataCustomization.membershipType.dropdown.searchPlaceholder': never;

		'dataCustomization.membershipType.dropdown.someOptionsSelected': {
			number: string | number;
		};

		'dataCustomization.membershipType.dropdown.title': never;

		'dataCustomization.membershipType.membershipTypeSummaryList.allOptionsSelected': {
			number: string | number;
		};

		'dataCustomization.membershipType.membershipTypeSummaryList.prefix': never;

		'dataCustomization.membershipType.membershipTypeSummaryList.someOptionsSelected': {
			number: string | number;
		};

		'dataCustomization.membershipType.membershipTypeSummaryList.tooltipContent': never;

		'dataCustomization.membershipType.successToast': never;

		'dataCustomization.membershipType.title': never;

		'dataCustomization.note': never;

		'dataCustomization.noteContent': never;

		'dataCustomization.streamingAttendance.description': never;

		'dataCustomization.streamingAttendance.learnAboutLabel': never;

		'dataCustomization.streamingAttendance.switchLabel': never;

		'dataCustomization.streamingAttendance.title': never;

		'dataCustomization.summary.text': never;

		'dataCustomization.summary.tooltip': never;

		dataIntegrity: never;

		'dateRangeSelector.apply': never;

		'dateRangeSelector.compareTo': never;

		'dateRangeSelector.comparisonOptions.None': never;

		'dateRangeSelector.comparisonOptions.PreviousPeriod': never;

		'dateRangeSelector.comparisonOptions.PreviousYear': never;

		'dateRangeSelector.dateRange': never;

		'dateRangeSelector.rangeOptions.custom': never;

		'dateRangeSelector.rangeOptions.last12Months': never;

		'dateRangeSelector.rangeOptions.last12Weeks': never;

		'dateRangeSelector.rangeOptions.lastMonth': never;

		'dateRangeSelector.rangeOptions.lastWeek': never;

		'dateRangeSelector.rangeOptions.thisMonth': never;

		'dateRangeSelector.rangeOptions.thisWeek': never;

		donors: never;

		'downloadManager.amplifyingText': never;

		'downloadManager.cancel': never;

		'downloadManager.canceling': never;

		'downloadManager.clear': never;

		'downloadManager.clearAll': never;

		'downloadManager.close': never;

		'downloadManager.delete': never;

		'downloadManager.download': never;

		'downloadManager.downloadManager': never;

		'downloadManager.downloads': never;

		'downloadManager.exportCanceled': never;

		'downloadManager.exportFailed': never;

		'downloadManager.exportPending': never;

		'downloadManager.exportQueued': never;

		'downloadManager.keepTrack': never;

		'downloadManager.retry': never;

		'downloadManager.retrying': never;

		'errors.accessDenied': never;

		'errors.contentNotGenerated': never;

		'errors.contentNotLoaded': never;

		'errors.contentNotLoadedPage': never;

		'errors.dateRangeTooLarge': never;

		'errors.error': never;

		'errors.failedIdentity': never;

		'errors.highTrafficVolume.message': never;

		'errors.highTrafficVolume.title': never;

		'errors.identityNotLoaded': never;

		'errors.notFound': never;

		'errors.snapshotNotLoaded': never;

		'errors.snapshotNotLoadedShort': never;

		'errors.somethingWentWrong': never;

		'errors.warning': never;

		expandMenu: never;

		export: never;

		'feedback.label': never;

		'feedback.submitATicket': never;

		'feedback.submitFeatureIdeas': never;

		'filters.buttons.apply': never;

		'filters.buttons.clear': never;

		'filters.labels.attendance.attendanceGroupingNames': never;

		'filters.labels.attendance.attendanceType': never;

		'filters.labels.attendance.campusNames': never;

		'filters.labels.attendance.departmentNames': never;

		'filters.labels.attendance.eventDays': never;

		'filters.labels.attendance.eventNames.filterTitle': never;

		'filters.labels.attendance.eventNames.inPersonSectionTitle': never;

		'filters.labels.attendance.eventNames.inPersonSelectAll': never;

		'filters.labels.attendance.eventNames.streamingSectionTitle': never;

		'filters.labels.attendance.eventNames.streamingSelectAll': never;

		'filters.labels.attendance.groupNames': never;

		'filters.labels.attendance.groupTypeNames': never;

		'filters.labels.attendance.membershipTypeIds': never;

		'filters.labels.groupParticipation.activeGroups': never;

		'filters.labels.groupParticipation.allActiveGroups': never;

		'filters.labels.groupParticipation.allInactiveGroups': never;

		'filters.labels.groupParticipation.campusNames': never;

		'filters.labels.groupParticipation.departmentNames': never;

		'filters.labels.groupParticipation.groupInteractionTypes': never;

		'filters.labels.groupParticipation.groupNames': never;

		'filters.labels.groupParticipation.groupTypeNames': never;

		'filters.labels.groupParticipation.inactivated': never;

		'filters.labels.groupParticipation.inactiveGroups': never;

		'filters.labels.groupParticipation.membershipTypeIds': never;

		'filters.labels.peopleList.attendanceStatus': never;

		'filters.labels.peopleList.campusKeys': never;

		'filters.labels.peopleList.donorStatus': never;

		'filters.labels.peopleList.membershipTypeIds': never;

		'filters.labels.peopleList.servingStatus': never;

		'filters.labels.serving.campusNames': never;

		'filters.labels.serving.categoryNames': never;

		'filters.labels.serving.membershipTypeIds': never;

		'filters.labels.serving.positionNames': never;

		'filters.labels.serving.scheduleNames': never;

		'filters.labels.serving.teamNames': never;

		'filters.staticFilters.attendanceStatus.options.FirstTime': never;

		'filters.staticFilters.attendanceStatus.options.Lapsed': never;

		'filters.staticFilters.attendanceStatus.options.NonAttenders': never;

		'filters.staticFilters.attendanceStatus.options.Occasional': never;

		'filters.staticFilters.attendanceStatus.options.Regular': never;

		'filters.staticFilters.attendanceStatus.options.SecondTime': never;

		'filters.staticFilters.attendanceType.options.headcount': never;

		'filters.staticFilters.attendanceType.options.individual': never;

		'filters.staticFilters.attendanceType.options.streaming': never;

		'filters.staticFilters.donorStatus.options.FirstTime': never;

		'filters.staticFilters.donorStatus.options.Lapsed': never;

		'filters.staticFilters.donorStatus.options.NonDonors': never;

		'filters.staticFilters.donorStatus.options.Occasional': never;

		'filters.staticFilters.donorStatus.options.Recurring': never;

		'filters.staticFilters.donorStatus.options.Regular': never;

		'filters.staticFilters.donorStatus.options.SecondTime': never;

		'filters.staticFilters.eventDays.options.friday': never;

		'filters.staticFilters.eventDays.options.monday': never;

		'filters.staticFilters.eventDays.options.saturday': never;

		'filters.staticFilters.eventDays.options.sunday': never;

		'filters.staticFilters.eventDays.options.thursday': never;

		'filters.staticFilters.eventDays.options.tuesday': never;

		'filters.staticFilters.eventDays.options.wednesday': never;

		'filters.staticFilters.groupInteractionType.options.a': never;

		'filters.staticFilters.groupInteractionType.options.d': never;

		'filters.staticFilters.groupInteractionType.options.i': never;

		'filters.staticFilters.servingStatus.options.FirstTime': never;

		'filters.staticFilters.servingStatus.options.Lapsed': never;

		'filters.staticFilters.servingStatus.options.NonVolunteers': never;

		'filters.staticFilters.servingStatus.options.Occasional': never;

		'filters.staticFilters.servingStatus.options.Regular': never;

		'filters.staticFilters.servingStatus.options.SecondTime': never;

		'filters.toasts.information.message': never;

		'filters.toasts.information.title': never;

		'filters.tooltips.disabled.attendanceStatus': never;

		'filters.tooltips.disabled.donorStatus': never;

		'filters.tooltips.disabled.membershipTypeIds': never;

		'filters.tooltips.disabled.servingStatus': never;

		'flyoutMenu.moreMenu': never;

		giving: never;

		'givingOverview.latest.description': never;

		'givingOverview.latest.heading': never;

		'givingOverview.topDonors.dateRangePrefix.last12Weeks': never;

		'givingOverview.topDonors.filtersNotApplied.label': never;

		'givingOverview.topDonors.filtersNotApplied.tooltip': never;

		'givingOverview.topDonors.tableAriaLabel': never;

		'givingOverview.topDonors.viewAll': never;

		'globalNav.help': never;

		'globalNav.helpAndSupportDrawer.header': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.relatedArticles.links.ConceptsAndDefinitions': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.relatedArticles.links.FAQ': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.relatedArticles.links.Introduction': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.relatedArticles.links.OverviewPages': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.relatedArticles.links.PeopleList': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.relatedArticles.title': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.relatedArticles.viewAll': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.resourceCards.HelpCenter.description': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.resourceCards.HelpCenter.title': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.resourceCards.PushpayUniversity.description': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.resourceCards.PushpayUniversity.title': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.resourceCards.TheVillage.description': never;

		'globalNav.helpAndSupportDrawer.helpAndSupportCards.resourceCards.TheVillage.title': never;

		'globalNav.helpAndSupportDrawer.overview.title': never;

		'globalNav.helpAndSupportDrawer.search.button': never;

		'globalNav.helpAndSupportDrawer.search.label': never;

		'globalNav.helpAndSupportDrawer.search.placeholder': never;

		'globalNav.helpAndSupportDrawer.sectionHeaders.forYou': never;

		'globalNav.helpAndSupportDrawer.sectionHeaders.resources': never;

		'globalNav.helpAndSupportDrawer.submitATicket.header': never;

		'globalNav.helpAndSupportDrawer.submitATicket.subheader': never;

		'globalNav.helpAndSupportDrawer.submitFeatureIdeas.header': never;

		'globalNav.helpAndSupportDrawer.submitFeatureIdeas.subheader': never;

		'globalNav.helpAndSupportDrawer.tooltip': never;

		'globalNav.helpTooltip': never;

		'globalNav.productSwitcher.appStudio': never;

		'globalNav.productSwitcher.buttonLabel': never;

		'globalNav.productSwitcher.chms': never;

		'globalNav.productSwitcher.description.appStudio': never;

		'globalNav.productSwitcher.description.chms': never;

		'globalNav.productSwitcher.description.giving': never;

		'globalNav.productSwitcher.description.insights': never;

		'globalNav.productSwitcher.giving': never;

		'globalNav.productSwitcher.insights': never;

		'globalNav.productSwitcher.learnAbout': {
			label: string | number;
		};

		'globalNav.productSwitcher.learnMore.appStudio': never;

		'globalNav.productSwitcher.noOrgAccessTitle': never;

		'globalNav.productSwitcher.pushpayMarketing.title': never;

		'globalNav.productSwitcher.title': never;

		'globalNav.productSwitcher.tooltips.noPermission': never;

		'globalNav.productSwitcher.tooltips.organizationNoProduct': never;

		'globalNav.profileMenu.loggingOut': never;

		'globalNav.profileMenu.logOut': never;

		'globalNav.profileMenu.personalAccount': never;

		'globalNav.profileMenu.profileButton': never;

		groupParticipants: never;

		'groupParticipationOverview.latest.description': never;

		'groupParticipationOverview.latest.heading': never;

		hide: never;

		home: never;

		incompleteData: never;

		'infoText.attendance.averages.Daily': never;

		'infoText.attendance.averages.Monthly': never;

		'infoText.attendance.averages.Weekly': never;

		'infoText.attendance.averages.Yearly': never;

		'infoText.attendance.churn': never;

		'infoText.attendance.headcount': never;

		'infoText.attendance.householdUnique': never;

		'infoText.attendance.individual': never;

		'infoText.attendance.individualUnique': never;

		'infoText.attendance.peopleList.All.CommunityMember': never;

		'infoText.attendance.peopleList.All.Household': never;

		'infoText.attendance.peopleList.AtRisk.CommunityMember': never;

		'infoText.attendance.peopleList.AtRisk.Household': never;

		'infoText.attendance.peopleList.Curious.CommunityMember': never;

		'infoText.attendance.peopleList.Curious.Household': never;

		'infoText.attendance.peopleList.FirstTime.CommunityMember': never;

		'infoText.attendance.peopleList.FirstTime.Household': never;

		'infoText.attendance.peopleList.Lapsed.CommunityMember': never;

		'infoText.attendance.peopleList.Lapsed.Household': never;

		'infoText.attendance.peopleList.NonAttenders.CommunityMember': never;

		'infoText.attendance.peopleList.NonAttenders.Household': never;

		'infoText.attendance.peopleList.Occasional.CommunityMember': never;

		'infoText.attendance.peopleList.Occasional.Household': never;

		'infoText.attendance.peopleList.Potential.CommunityMember': never;

		'infoText.attendance.peopleList.Potential.Household': never;

		'infoText.attendance.peopleList.PotentialInactive.CommunityMember': never;

		'infoText.attendance.peopleList.PotentialInactive.Household': never;

		'infoText.attendance.peopleList.RecentNew.CommunityMember': never;

		'infoText.attendance.peopleList.RecentNew.Household': never;

		'infoText.attendance.peopleList.Regular.CommunityMember': never;

		'infoText.attendance.peopleList.Regular.Household': never;

		'infoText.attendance.peopleList.Returning.CommunityMember': never;

		'infoText.attendance.peopleList.Returning.Household': never;

		'infoText.attendance.peopleList.SecondTime.CommunityMember': never;

		'infoText.attendance.peopleList.SecondTime.Household': never;

		'infoText.attendance.streaming': never;

		'infoText.attendance.total': never;

		'infoText.giving.avgDonation': never;

		'infoText.giving.avgPerAttendee': never;

		'infoText.giving.household': never;

		'infoText.giving.individual': never;

		'infoText.giving.peopleList.All.CommunityMember': never;

		'infoText.giving.peopleList.All.Household': never;

		'infoText.giving.peopleList.AtRisk.CommunityMember': never;

		'infoText.giving.peopleList.AtRisk.Household': never;

		'infoText.giving.peopleList.FirstTime.CommunityMember': never;

		'infoText.giving.peopleList.FirstTime.Household': never;

		'infoText.giving.peopleList.Lapsed.CommunityMember': never;

		'infoText.giving.peopleList.Lapsed.Household': never;

		'infoText.giving.peopleList.NewRecurring.CommunityMember': never;

		'infoText.giving.peopleList.NewRecurring.Household': never;

		'infoText.giving.peopleList.newRecurringPeopleList.CommunityMember': never;

		'infoText.giving.peopleList.newRecurringPeopleList.Household': never;

		'infoText.giving.peopleList.NonDonors.CommunityMember': never;

		'infoText.giving.peopleList.NonDonors.Household': never;

		'infoText.giving.peopleList.Occasional.CommunityMember': never;

		'infoText.giving.peopleList.Occasional.Household': never;

		'infoText.giving.peopleList.Potential.CommunityMember': never;

		'infoText.giving.peopleList.Potential.Household': never;

		'infoText.giving.peopleList.RecentNew.CommunityMember': never;

		'infoText.giving.peopleList.RecentNew.Household': never;

		'infoText.giving.peopleList.Recurring.CommunityMember': never;

		'infoText.giving.peopleList.Recurring.Household': never;

		'infoText.giving.peopleList.Regular.CommunityMember': never;

		'infoText.giving.peopleList.Regular.Household': never;

		'infoText.giving.peopleList.SecondTime.CommunityMember': never;

		'infoText.giving.peopleList.SecondTime.Household': never;

		'infoText.giving.peopleList.TopDonors.columnDateRangeToolTip': never;

		'infoText.giving.peopleList.TopDonors.CommunityMember': never;

		'infoText.giving.peopleList.TopDonors.Household': never;

		'infoText.giving.ratioOnlineOffline': never;

		'infoText.giving.total': never;

		'infoText.groupParticipation.averageGroupSize': never;

		'infoText.groupParticipation.medianGroupSize': never;

		'infoText.groupParticipation.memberLeaderRatio': never;

		'infoText.groupParticipation.totalGroupParticipantHouseholds': never;

		'infoText.groupParticipation.totalGroupParticipants': never;

		'infoText.groupParticipation.totalGroups': never;

		'infoText.groupParticipation.uniqueGroupParticipants': never;

		'infoText.groupParticipation.uniqueParticipantHouseholds': never;

		'infoText.serving.avgOpportunities': never;

		'infoText.serving.household': never;

		'infoText.serving.individual': never;

		'infoText.serving.peopleList.All.CommunityMember': never;

		'infoText.serving.peopleList.All.Household': never;

		'infoText.serving.peopleList.AtRisk.CommunityMember': never;

		'infoText.serving.peopleList.AtRisk.Household': never;

		'infoText.serving.peopleList.Curious.CommunityMember': never;

		'infoText.serving.peopleList.Curious.Household': never;

		'infoText.serving.peopleList.FirstTime.CommunityMember': never;

		'infoText.serving.peopleList.FirstTime.Household': never;

		'infoText.serving.peopleList.Lapsed.CommunityMember': never;

		'infoText.serving.peopleList.Lapsed.Household': never;

		'infoText.serving.peopleList.NonVolunteers.CommunityMember': never;

		'infoText.serving.peopleList.NonVolunteers.Household': never;

		'infoText.serving.peopleList.Occasional.CommunityMember': never;

		'infoText.serving.peopleList.Occasional.Household': never;

		'infoText.serving.peopleList.Potential.CommunityMember': never;

		'infoText.serving.peopleList.Potential.Household': never;

		'infoText.serving.peopleList.RecentNew.CommunityMember': never;

		'infoText.serving.peopleList.RecentNew.Household': never;

		'infoText.serving.peopleList.Regular.CommunityMember': never;

		'infoText.serving.peopleList.Regular.Household': never;

		'infoText.serving.peopleList.Returning.CommunityMember': never;

		'infoText.serving.peopleList.Returning.Household': never;

		'infoText.serving.peopleList.SecondTime.CommunityMember': never;

		'infoText.serving.peopleList.SecondTime.Household': never;

		'infoText.serving.positions': never;

		'infoText.serving.ratioVolunteersOpportunities': never;

		'infoText.serving.rolesUnfilled': never;

		'infoText.serving.teams': never;

		label: never;

		'languageMenu.back': never;

		'languages.english': never;

		'languages.spanish': never;

		loading: never;

		loadLink: never;

		noData: never;

		overview: never;

		'pageHeader.attendance': never;

		'pageHeader.dataCustomization': never;

		'pageHeader.giving': never;

		'pageHeader.groupParticipation': never;

		'pageHeader.groups': never;

		'pageHeader.peopleList': never;

		'pageHeader.serving': never;

		'peopleList.actions.buttonLabel': never;

		'peopleList.actions.export': never;

		'peopleList.actions.sendingToProcessQueue': never;

		'peopleList.actions.sendToProcessQueue': never;

		'peopleList.actions.viewProcessQueue': never;

		'peopleList.allHouseholdCountDescription': never;

		'peopleList.allPeopleCountDescription': never;

		'peopleList.exportListType.Attenders.atRisk': never;

		'peopleList.exportListType.Attenders.firstTime': never;

		'peopleList.exportListType.Attenders.lapsed': never;

		'peopleList.exportListType.Attenders.non': never;

		'peopleList.exportListType.Attenders.occasional': never;

		'peopleList.exportListType.Attenders.recentNew': never;

		'peopleList.exportListType.Attenders.regular': never;

		'peopleList.exportListType.Attenders.returning': never;

		'peopleList.exportListType.Attenders.secondTime': never;

		'peopleList.exportListType.Donors.atRisk': never;

		'peopleList.exportListType.Donors.lapsed': never;

		'peopleList.exportListType.Donors.new': never;

		'peopleList.exportListType.Donors.non': never;

		'peopleList.exportListType.Donors.occasional': never;

		'peopleList.exportListType.Donors.potential': never;

		'peopleList.exportListType.Donors.recentNew': never;

		'peopleList.exportListType.Donors.recurring': never;

		'peopleList.exportListType.Donors.regular': never;

		'peopleList.exportListType.Donors.returning': never;

		'peopleList.exportListType.Donors.secondTime': never;

		'peopleList.exportListType.Volunteers.atRisk': never;

		'peopleList.exportListType.Volunteers.firstTime': never;

		'peopleList.exportListType.Volunteers.lapsed': never;

		'peopleList.exportListType.Volunteers.non': never;

		'peopleList.exportListType.Volunteers.occasional': never;

		'peopleList.exportListType.Volunteers.recentNew': never;

		'peopleList.exportListType.Volunteers.regular': never;

		'peopleList.exportListType.Volunteers.returning': never;

		'peopleList.exportListType.Volunteers.secondTime': never;

		'peopleList.floatingActionMenu.buttons.ascending': never;

		'peopleList.floatingActionMenu.buttons.clear': never;

		'peopleList.floatingActionMenu.buttons.descending': never;

		'peopleList.floatingActionMenu.buttons.sort': never;

		'peopleList.floatingActionMenu.buttons.sorting': never;

		'peopleList.floatingActionMenu.sortLabel': never;

		'peopleList.listType.all': never;

		'peopleList.listType.allHouseholds': never;

		'peopleList.listType.atRisk': never;

		'peopleList.listType.curious': never;

		'peopleList.listType.firstTime': never;

		'peopleList.listType.lapsed': never;

		'peopleList.listType.newRecurring': never;

		'peopleList.listType.nonAttenders': never;

		'peopleList.listType.nonDonors': never;

		'peopleList.listType.nonVolunteers': never;

		'peopleList.listType.occasional': never;

		'peopleList.listType.potential': never;

		'peopleList.listType.potentialInactive': never;

		'peopleList.listType.potentialInactiveHousehold': never;

		'peopleList.listType.recentNew': never;

		'peopleList.listType.recurring': never;

		'peopleList.listType.regular': never;

		'peopleList.listType.returning': never;

		'peopleList.listType.secondTime': never;

		'peopleList.listType.topDonors': never;

		'peopleList.noDataDescription': never;

		'peopleList.noDataTitle': never;

		'peopleList.numberOf': never;

		'peopleList.otherLists': never;

		'peopleList.rowActions.chmsProfile.description': never;

		'peopleList.rowActions.chmsProfile.label': never;

		'peopleList.rowActions.givingProfile.description': never;

		'peopleList.rowActions.givingProfile.label': never;

		'peopleList.rowActions.givingStatement.description': never;

		'peopleList.rowActions.givingStatement.label': never;

		'peopleList.rowActions.mobileRowActionButtonTitle': never;

		'peopleList.search.button': never;

		'peopleList.search.label': never;

		'peopleList.search.placeholder': never;

		'peopleList.sendToProcessQueueModal.ariaLabels.closeModal': never;

		'peopleList.sendToProcessQueueModal.confirmation.body': never;

		'peopleList.sendToProcessQueueModal.confirmation.title': never;

		'peopleList.sendToProcessQueueModal.listTooLarge.body': {
			number: string | number;
		};

		'peopleList.sendToProcessQueueModal.listTooLarge.title': never;

		'peopleList.sendToProcessQueueModal.processDropdown.placeholder': never;

		'peopleList.sendToProcessQueueModal.processDropdown.title': never;

		'peopleList.sendToProcessQueueModal.queueDropdown.disabled': never;

		'peopleList.sendToProcessQueueModal.queueDropdown.placeholder': never;

		'peopleList.sendToProcessQueueModal.queueDropdown.title': never;

		'peopleList.sendToProcessQueueModal.sendError.body': never;

		'peopleList.sendToProcessQueueModal.sendError.title': never;

		'peopleList.sendToProcessQueueModal.title': never;

		'peopleList.sendToProcessQueueModal.warningMessage': {
			number: string | number;
		};

		'peopleList.stages': never;

		'peopleList.tableActions.next': never;

		'peopleList.tableActions.previous': never;

		'peopleList.tableAriaLabel': never;

		'peopleList.tableColumns.attendanceStatus': never;

		'peopleList.tableColumns.createdOn': never;

		'peopleList.tableColumns.email': never;

		'peopleList.tableColumns.firstName': never;

		'peopleList.tableColumns.givingStatus': never;

		'peopleList.tableColumns.givingTotal': never;

		'peopleList.tableColumns.homeCampusName': never;

		'peopleList.tableColumns.householdName': never;

		'peopleList.tableColumns.lastGiftAmount': never;

		'peopleList.tableColumns.lastGiftDate': never;

		'peopleList.tableColumns.lastGiftFund': never;

		'peopleList.tableColumns.lastGiftListing': never;

		'peopleList.tableColumns.lastGiftSection': never;

		'peopleList.tableColumns.lastName': never;

		'peopleList.tableColumns.lifeTimeGivingTotal': never;

		'peopleList.tableColumns.memberCount': never;

		'peopleList.tableColumns.membershipType': never;

		'peopleList.tableColumns.numberOfGifts': never;

		'peopleList.tableColumns.phone': never;

		'peopleList.tableColumns.phoneNumber': never;

		'peopleList.tableColumns.servingStatus': never;

		'peopleList.tableColumns.yearToDateSection': never;

		'peopleList.tableColumns.ytd': never;

		'peopleList.tableColumns.ytdLargestGiftAmount': never;

		'peopleList.tableColumns.ytdLargestGiftDate': never;

		'peopleList.tableColumns.ytdLargestGiftFund': never;

		'peopleList.tableColumns.ytdLargestGiftListing': never;

		'peopleList.viewBy': never;

		'peopleList.viewByDomain.Attenders': never;

		'peopleList.viewByDomain.Donors': never;

		'peopleList.viewByDomain.GroupParticipants': never;

		'peopleList.viewByDomain.mobileHeader.Attenders': never;

		'peopleList.viewByDomain.mobileHeader.Donors': never;

		'peopleList.viewByDomain.mobileHeader.GroupParticipants': never;

		'peopleList.viewByDomain.mobileHeader.Volunteers': never;

		'peopleList.viewByDomain.Volunteers': never;

		'peopleList.viewByMode.CommunityMember': never;

		'peopleList.viewByMode.Household': never;

		png: never;

		'ratioChart.attenders.attending.label': never;

		'ratioChart.attenders.attending.tooltip': never;

		'ratioChart.attenders.donutChartTooltip': never;

		'ratioChart.attenders.label': never;

		'ratioChart.attenders.notAttending.label': never;

		'ratioChart.attenders.notAttending.tooltip': never;

		'ratioChart.attenders.title': never;

		'ratioChart.donors.donutChartTooltip.CommunityMember': never;

		'ratioChart.donors.donutChartTooltip.Household': never;

		'ratioChart.donors.giving.label': never;

		'ratioChart.donors.giving.tooltip': never;

		'ratioChart.donors.label.CommunityMember': never;

		'ratioChart.donors.label.Household': never;

		'ratioChart.donors.notGiving.label': never;

		'ratioChart.donors.notGiving.tooltip': never;

		'ratioChart.donors.title.CommunityMember': never;

		'ratioChart.donors.title.Household': never;

		'ratioChart.groupParticipation.donutChartTooltip': never;

		'ratioChart.groupParticipation.inGroup.label': never;

		'ratioChart.groupParticipation.inGroup.tooltip': never;

		'ratioChart.groupParticipation.label': never;

		'ratioChart.groupParticipation.notInGroup.label': never;

		'ratioChart.groupParticipation.notInGroup.tooltip': never;

		'ratioChart.groupParticipation.title': never;

		'ratioChart.volunteers.donutChartTooltip': never;

		'ratioChart.volunteers.label': never;

		'ratioChart.volunteers.notServing.label': never;

		'ratioChart.volunteers.notServing.tooltip': never;

		'ratioChart.volunteers.serving.label': never;

		'ratioChart.volunteers.serving.tooltip': never;

		'ratioChart.volunteers.title': never;

		refresh: never;

		refreshPage: never;

		serving: never;

		'servingOverview.latest.description': never;

		'servingOverview.latest.heading': never;

		settings: never;

		show: never;

		showMenu: never;

		'snapshots.Attenders.atRiskAttenders': never;

		'snapshots.Attenders.attendanceChurn': never;

		'snapshots.Attenders.dateRangeLabel': never;

		'snapshots.Attenders.notApplied.text': never;

		'snapshots.Attenders.notApplied.tooltip': {
			dateRange: string | number;
		};

		'snapshots.Attenders.potentialAttenders': never;

		'snapshots.Attenders.potentialInactive': never;

		'snapshots.Attenders.recentNewAttenders': never;

		'snapshots.Attenders.title': never;

		'snapshots.Donors.atRiskDonors.CommunityMember': never;

		'snapshots.Donors.atRiskDonors.Household': never;

		'snapshots.Donors.dateRangeLabel': never;

		'snapshots.Donors.givingPerAttendee': never;

		'snapshots.Donors.notApplied.text': never;

		'snapshots.Donors.notApplied.tooltip': {
			dateRange: string | number;
		};

		'snapshots.Donors.onlineOfflineGiving': never;

		'snapshots.Donors.potentialDonors.CommunityMember': never;

		'snapshots.Donors.potentialDonors.Household': never;

		'snapshots.Donors.recentNewDonors.CommunityMember': never;

		'snapshots.Donors.recentNewDonors.Household': never;

		'snapshots.Donors.title': never;

		'snapshots.GroupParticipants.dateRangeLabel': never;

		'snapshots.GroupParticipants.notApplied.text': never;

		'snapshots.GroupParticipants.notApplied.tooltip': {
			dateRange: string | number;
		};

		'snapshots.overviewTitle.communityMember': never;

		'snapshots.overviewTitle.household': never;

		'snapshots.Volunteers.atRiskVolunteers': never;

		'snapshots.Volunteers.dateRangeLabel': never;

		'snapshots.Volunteers.notApplied.text': never;

		'snapshots.Volunteers.notApplied.tooltip': {
			dateRange: string | number;
		};

		'snapshots.Volunteers.potentialVolunteers': never;

		'snapshots.Volunteers.recentNewVolunteers': never;

		'snapshots.Volunteers.title': never;

		'snapshots.Volunteers.unfilledRoles': never;

		'snapshots.Volunteers.volunteersOpportunities': never;

		'stages.attendance': never;

		'stages.firstTimeAttenders': never;

		'stages.firstTimeVolunteers': never;

		'stages.giving.firstTimeDonors.CommunityMember': never;

		'stages.giving.firstTimeDonors.Household': never;

		'stages.giving.lapsedDonors.CommunityMember': never;

		'stages.giving.lapsedDonors.Household': never;

		'stages.giving.nonDonors.CommunityMember': never;

		'stages.giving.nonDonors.Household': never;

		'stages.giving.occasionalDonors.CommunityMember': never;

		'stages.giving.occasionalDonors.Household': never;

		'stages.giving.recurringDonors.CommunityMember': never;

		'stages.giving.recurringDonors.Household': never;

		'stages.giving.regularDonors.CommunityMember': never;

		'stages.giving.regularDonors.Household': never;

		'stages.giving.secondTimeDonors.CommunityMember': never;

		'stages.giving.secondTimeDonors.Household': never;

		'stages.giving.title.CommunityMember': never;

		'stages.giving.title.Household': never;

		'stages.lapsedAttenders': never;

		'stages.lapsedVolunteers': never;

		'stages.nonAttenders': never;

		'stages.nonVolunteers': never;

		'stages.occasionalAttenders': never;

		'stages.occasionalVolunteers': never;

		'stages.regularAttenders': never;

		'stages.regularVolunteers': never;

		'stages.secondTimeAttenders': never;

		'stages.secondTimeVolunteers': never;

		'stages.totalLabel.CommunityMember': never;

		'stages.totalLabel.Household': never;

		'stages.volunteers': never;

		'table.pagination.next': never;

		'table.pagination.previous': never;

		'table.pagination.records': never;

		'tableColumnConfigurationPanel.apply': never;

		'tableColumnConfigurationPanel.columnSettings': never;

		'tableColumnConfigurationPanel.configureTextTooltip': never;

		'tableColumnConfigurationPanel.noColumnSelected': never;

		'tableColumnConfigurationPanel.resetToDefault': never;

		'tableColumnConfigurationPanel.selectAll': never;

		'tableColumnConfigurationPanel.subtext': never;

		testData: never;

		'toasts.success': never;

		toggle: never;

		'topDonorsOverview.title.CommunityMember': never;

		'topDonorsOverview.title.Household': never;

		trendNoComparisonTooltip: never;

		trendTooltip: never;

		volunteers: never;

		'widget.ariaLabel.dataTable': never;

		'widget.comparisonLabels.currentTitleLabel.Custom': never;

		'widget.comparisonLabels.currentTitleLabel.None.one.current.Day': never;

		'widget.comparisonLabels.currentTitleLabel.None.one.current.Month': never;

		'widget.comparisonLabels.currentTitleLabel.None.one.current.Week': never;

		'widget.comparisonLabels.currentTitleLabel.None.one.current.Year': never;

		'widget.comparisonLabels.currentTitleLabel.None.one.last.Day': never;

		'widget.comparisonLabels.currentTitleLabel.None.one.last.Month': never;

		'widget.comparisonLabels.currentTitleLabel.None.one.last.Week': never;

		'widget.comparisonLabels.currentTitleLabel.None.one.last.Year': never;

		'widget.comparisonLabels.currentTitleLabel.None.twelve.Day': never;

		'widget.comparisonLabels.currentTitleLabel.None.twelve.Month': never;

		'widget.comparisonLabels.currentTitleLabel.None.twelve.Week': never;

		'widget.comparisonLabels.currentTitleLabel.None.twelve.Year': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.one.current.Day': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.one.current.Month': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.one.current.Week': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.one.current.Year': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.one.last.Day': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.one.last.Month': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.one.last.Week': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.one.last.Year': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.twelve.Day': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.twelve.Month': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.twelve.Week': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousPeriod.twelve.Year': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.one.current.Day': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.one.current.Month': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.one.current.Week': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.one.current.Year': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.one.last.Day': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.one.last.Month': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.one.last.Week': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.one.last.Year': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.twelve.Day': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.twelve.Month': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.twelve.Week': never;

		'widget.comparisonLabels.currentTitleLabel.PreviousYear.twelve.Year': never;

		'widget.comparisonLabels.missingData': never;

		'widget.comparisonLabels.percentageSuffix.decrease': never;

		'widget.comparisonLabels.percentageSuffix.increase': never;

		'widget.comparisonLabels.percentageSuffix.noChange': never;

		'widget.comparisonLabels.percentageSuffix.noComparableData': never;

		'widget.comparisonLabels.previousTitleLabel.None.one.current.Day': never;

		'widget.comparisonLabels.previousTitleLabel.None.one.current.Month': never;

		'widget.comparisonLabels.previousTitleLabel.None.one.current.Week': never;

		'widget.comparisonLabels.previousTitleLabel.None.one.current.Year': never;

		'widget.comparisonLabels.previousTitleLabel.None.one.last.Day': never;

		'widget.comparisonLabels.previousTitleLabel.None.one.last.Month': never;

		'widget.comparisonLabels.previousTitleLabel.None.one.last.Week': never;

		'widget.comparisonLabels.previousTitleLabel.None.one.last.Year': never;

		'widget.comparisonLabels.previousTitleLabel.None.twelve.Day': never;

		'widget.comparisonLabels.previousTitleLabel.None.twelve.Month': never;

		'widget.comparisonLabels.previousTitleLabel.None.twelve.Week': never;

		'widget.comparisonLabels.previousTitleLabel.None.twelve.Year': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.one.current.Day': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.one.current.Month': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.one.current.Week': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.one.current.Year': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.one.last.Day': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.one.last.Month': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.one.last.Week': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.one.last.Year': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.twelve.Day': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.twelve.Month': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.twelve.Week': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousPeriod.twelve.Year': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.one.current.Day': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.one.current.Month': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.one.current.Week': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.one.current.Year': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.one.last.Day': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.one.last.Month': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.one.last.Week': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.one.last.Year': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.twelve.Day': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.twelve.Month': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.twelve.Week': never;

		'widget.comparisonLabels.previousTitleLabel.PreviousYear.twelve.Year': never;

		'widget.comparisonLabels.tooltip.title.custom': never;

		'widget.comparisonLabels.tooltip.title.daysOfTheWeek.Friday': never;

		'widget.comparisonLabels.tooltip.title.daysOfTheWeek.Monday': never;

		'widget.comparisonLabels.tooltip.title.daysOfTheWeek.Saturday': never;

		'widget.comparisonLabels.tooltip.title.daysOfTheWeek.Sunday': never;

		'widget.comparisonLabels.tooltip.title.daysOfTheWeek.Thursday': never;

		'widget.comparisonLabels.tooltip.title.daysOfTheWeek.Tuesday': never;

		'widget.comparisonLabels.tooltip.title.daysOfTheWeek.Wednesday': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.Apr': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.Aug': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.Dec': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.Feb': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.Jan': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.Jul': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.Jun': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.Mar': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.May': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.Nov': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.Oct': never;

		'widget.comparisonLabels.tooltip.title.monthsOfTheYear.Sep': never;

		'widget.comparisonLabels.tooltip.title.weekNumber': {
			number: string | number;
		};

		'widget.comparisonLabels.tooltip.title.yearVsYear': never;

		'widget.comparisonLabels.tooltip.Weekly': never;

		'widget.comparisonLabels.typeLabel.AttendanceOverview': never;

		'widget.comparisonLabels.typeLabel.GivingOverview': never;

		'widget.comparisonLabels.typeLabel.GroupParticipationOverview': never;

		'widget.comparisonLabels.typeLabel.ServingOverview': never;

		'widget.highlights.AttendanceOverview.averageAttendance.Daily': never;

		'widget.highlights.AttendanceOverview.averageAttendance.Monthly': never;

		'widget.highlights.AttendanceOverview.averageAttendance.Weekly': never;

		'widget.highlights.AttendanceOverview.averageAttendance.Yearly': never;

		'widget.highlights.AttendanceOverview.firstTimeGuests': never;

		'widget.highlights.AttendanceOverview.headCountAttendance': never;

		'widget.highlights.AttendanceOverview.individualAttendance': never;

		'widget.highlights.AttendanceOverview.streamingAttendance': never;

		'widget.highlights.AttendanceOverview.totalAttendance': never;

		'widget.highlights.AttendanceOverview.uniqueFamilyAttendance': never;

		'widget.highlights.AttendanceOverview.uniqueIndividualAttendance': never;

		'widget.highlights.GivingOverview.averageDonationAmount': never;

		'widget.highlights.GivingOverview.newRecurringDonors.CommunityMember': never;

		'widget.highlights.GivingOverview.newRecurringDonors.Household': never;

		'widget.highlights.GivingOverview.totalDonations': never;

		'widget.highlights.GivingOverview.totalDonors': never;

		'widget.highlights.GivingOverview.totalHouseholds': never;

		'widget.highlights.GroupParticipationOverview.averageGroupSize': never;

		'widget.highlights.GroupParticipationOverview.medianGroupSize': never;

		'widget.highlights.GroupParticipationOverview.memberLeaderRatio': never;

		'widget.highlights.GroupParticipationOverview.totalGroupParticipantHouseholds': never;

		'widget.highlights.GroupParticipationOverview.totalGroupParticipants': never;

		'widget.highlights.GroupParticipationOverview.totalGroups': never;

		'widget.highlights.GroupParticipationOverview.uniqueGroupParticipants': never;

		'widget.highlights.GroupParticipationOverview.uniqueParticipantHouseholds': never;

		'widget.highlights.ServingOverview.servingOpportunitiesAverage': never;

		'widget.highlights.ServingOverview.volunteerHouseholds': never;

		'widget.highlights.ServingOverview.volunteerIndividuals': never;

		'widget.highlights.ServingOverview.volunteerPositions': never;

		'widget.highlights.ServingOverview.volunteerTeams': never;

		'widget.title.AttendanceOverview.subtitle': never;

		'widget.title.AttendanceOverview.title': never;

		'widget.title.GivingOverview.subtitle': never;

		'widget.title.GivingOverview.title': never;

		'widget.title.GroupParticipationOverview.subtitle': never;

		'widget.title.GroupParticipationOverview.title': never;

		'widget.title.ServingOverview.subtitle': never;

		'widget.title.ServingOverview.title': never;

		'widget.tooltips.filtersButton': never;
	};

	forms: {
		'forms.error': never;

		'forms.requiredText': never;

		'forms.validation.emailAddress': never;

		'forms.validation.generic': never;

		'forms.validation.range': {
			minimum: string | number;
			maximum: string | number;
		};

		'forms.validation.regularExpression': never;

		'forms.validation.required': never;

		'forms.validation.stringLength': {
			minimumLength: string | number;
			maximumLength: string | number;
		};

		'forms.validation.stringLengthMaxOnly': {
			maximumLength: string | number;
		};

		'forms.validation.url': never;

		'forms.validation.key': never;

		'forms.validation.date': never;

		'forms.validation.notPastDate': never;

		'forms.validation.notFutureDate': never;

		'forms.validation.phoneNumber': never;

		'forms.validation.arrayLength': {
			minimumLength: string | number;
			maximumLength: string | number;
		};

		'forms.validation.arrayLengthMinOnly': {
			minimumLength: string | number;
		};

		'forms.validation.amountOutOfRange': never;

		'forms.validation.panPrevention': never;
	};
};

export type NamespaceKey = keyof TranslationLanguage;

export type Translate<TNamespaceKey extends NamespaceKey> = <TKey extends keyof TranslationLanguage[TNamespaceKey]>(
	key: TKey,
	...params: TranslationLanguage[TNamespaceKey][TKey] extends never
		? [undefined?]
		: [TranslationLanguage[TNamespaceKey][TKey]]
) => string;

/**
 * A utility hook for interacting with i18n. Caters for all basic functionality such as translation, changing language, listening to
 * language change etc.
 *
 * If you want to add translation post processors or require additional functionality eg. translate Moment create an index file with
 * your own hook that calls this hook internally
 */
export function useTranslation<TNamespaceKey extends NamespaceKey>(
	namespace: TNamespaceKey,
	options?: UseTranslationOptions,
	translationPostProcessors: ((text: string) => string)[] = []
) {
	const { t, i18n, ready } = useTranslationI18next(namespace, options);
	const { language, changeLanguage: changeLanguageI18n } = i18n;
	const translate = React.useCallback<Translate<TNamespaceKey>>(
		function translateInner(key, ...params) {
			const translatedText = t(key as any, ...(params as any)) as string;
			return translationPostProcessors.reduce((text, processor) => processor(text), translatedText);
		},
		[t, translationPostProcessors]
	);
	const keyExists = React.useCallback(
		(key: string | string[]) => i18n.exists(`${namespace}א${key}`),
		[namespace, i18n]
	);
	const changeLanguage = React.useCallback(
		(newLanguage: string | undefined) => changeLanguageI18n.bind(i18n)(newLanguage),
		[changeLanguageI18n, i18n]
	);
	const onLanguageChange = React.useCallback(
		(callback: (language: string) => void) => {
			i18n.on('languageChanged', callback);
			return () => i18n.off('languageChanged', callback);
		},
		[i18n]
	);
	const translateMoment = React.useCallback(
		(value: Moment, displayFormat = 'DD MMM YYYY') => value.clone().locale(language).format(displayFormat),
		[language]
	);

	return React.useMemo(
		() => ({
			translate,
			keyExists,
			language,
			changeLanguage,
			onLanguageChange,
			translateMoment,
			i18n,
			ready,
		}),
		[translate, keyExists, language, changeLanguage, onLanguageChange, translateMoment, i18n, ready]
	);
}

export type TransProps<
	TNamespaceKey extends NamespaceKey,
	TKey extends keyof TranslationLanguage[TNamespaceKey]
> = Omit<TransPropsI18next<never>, 'i18nKey' | 'values' | 'ns'> & {
	i18nKey: TKey;
	namespace?: TNamespaceKey;
} & (TranslationLanguage[TNamespaceKey][TKey] extends never
		? { values?: undefined }
		: { values: TranslationLanguage[TNamespaceKey][TKey] });

export const getTransWithDefaultNamespace =
	<TDefaultNamespace extends NamespaceKey>(_defaultNamespace: TDefaultNamespace) =>
	<TKey extends keyof TranslationLanguage[TNamespaceKey], TNamespaceKey extends NamespaceKey = TDefaultNamespace>({
		namespace,
		...rest
	}: TransProps<TNamespaceKey, TKey>) => {
		useTranslationI18next();
		return <TransI18next ns={namespace} {...(rest as any)} />;
	};
